// 微信上下文认证方式
function WechatFilter(allcontextMethods) {
  return allcontextMethods.filter(
    item =>
      item.type === "WECHAT_RZKC" ||
      item.type === "WECHAT_ENTERPRISE" ||
      item.type === "WECHAT_ENTERPRISE_PRIVATE" ||
      item.type === "WECHAT_WEBSITE" ||
      item.type === "WECHAT_OFFICIAL"
  );
}

// 企业微信上下文认证方式
function WechatEnterpriseFilter(allcontextMethods) {
  return allcontextMethods.filter(item => item.type === "WECHAT_ENTERPRISE");
}

// 企业微信上下文认证方式
function WechatEnterprisePrivateFilter(allcontextMethods) {
  return allcontextMethods.filter(
    item => item.type === "WECHAT_ENTERPRISE_PRIVATE"
  );
}

// 钉钉上下文认证方式
function DingTalkFilter(allcontextMethods) {
  return allcontextMethods.filter(item => item.type === "DING_TALK");
}

// 飞书上下文认证方式
function LarkFilter(allcontextMethods) {
  return allcontextMethods.filter(item => item.type === "LARK");
}

function hasVal(curMethod) {
  if (curMethod === "不启用" || curMethod === "无可用的认证方式") {
    return false;
  } else {
    return true;
  }
}

export {
  WechatFilter,
  WechatEnterpriseFilter,
  DingTalkFilter,
  LarkFilter,
  hasVal,
  WechatEnterprisePrivateFilter
};
